<template>
	<v-container fluid tag="section">

		<ti-card :icon="$icons.settings">
			<template #title>Settings</template>
			<template #title-actions-shown>

			</template>

		<v-data-table :headers="headers" :items="settings" item-key="name" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
			<template v-slot:top>
				<v-row justify="end">
					<v-col cols="12" lg="6">
						<v-text-field
							v-model="search"
							label="Search"
							prepend-icon="mdi-magnify"
							class="mx-4"
						></v-text-field>
					</v-col>
				</v-row>

			</template>
			<template v-slot:item.is_active="{ item }">
				<v-icon v-if="item.is_active" color="success">mdi-check</v-icon>
				<v-icon v-else color="error">mdi-close</v-icon>
			</template>
		</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "SettingsIndex",
	computed:{
		settings: sync('settings/settings')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Setting',
				align: 'start',
				sortable: true,
				value: 'setting_name',
			},
			{
				text: 'Value',
				align: 'start',
				sortable: true,
				value: 'setting_value',
			},
			{
				text: 'Group',
				align: 'start',
				sortable: true,
				value: 'group',
			}
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getSettings()
		},
		editItem(data){
			this.$router.push({name:'SettingsEdit', params: {id: data.id}})
		},
		getSettings(){
			this.$api.settings.index()
			.then(response => {
							this.settings = response.data
						})
						.catch(error => {
							this.$toast.add(error.message, 'error')
						})
		},
		filterOnlyCapsText (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>